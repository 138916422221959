<template>
  <div>
    <div class="container mt-5 mb-5 pb-5">
      <h1>Privacy Policy for "Walmart Wordwide" LLC</h1>
      <p class="pt-5">This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your
        information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
      <p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection
        and use of information in accordance with this Privacy Policy. This Privacy Policy has been created with the
        help of the Free Privacy Policy Generator.</p>
      <p>Interpretation and Definitions</p>
      <p>Interpretation</p>
      <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The
        following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
      </p>
      <p>Definitions</p>
      <p>For the purposes of this Privacy Policy:</p>
      <p>Account means a unique account created for You to access our Service or parts of our Service.</p>
      <p>Affiliate means an entity that controls, is controlled by or is under common control with a party, where
        "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for
        election of directors or other managing authority.</p>
      <p>Application refers to Walmart Wordwide, the software program provided by the Company.</p>
      <p>Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Walmart Wordwide
        LLC, T.Farogiy, house № 74/1.</p>
      <p>Country refers to: Uzbekistan</p>
      <p>Device means any device that can access the Service such as a computer, a cellphone or a digital tablet.</p>
      <p>Personal Data is any information that relates to an identified or identifiable individual.</p>
      <p>Service refers to the Application.</p>
      <p>Service Provider means any natural or legal person who processes the data on behalf of the Company. It refers
        to third-party companies or individuals employed by the Company to facilitate the Service, to provide the
        Service on behalf of the Company, to perform services related to the Service or to assist the Company in
        analyzing how the Service is used.</p>
      <p>Usage Data refers to data collected automatically, either generated by the use of the Service or from the
        Service infrastructure itself (for example, the duration of a page visit).</p>
      <p>You means the individual accessing or using the Service, or the company, or other legal entity on behalf of
        which such individual is accessing or using the Service, as applicable.</p>
      <p>Collecting and Using Your Personal Data</p>
      <p>Types of Data Collected</p>
      <p>Personal Data</p>
      <p>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can
        be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>
      <p>First name and last name<br>Phone number<br>Address, State, Province, ZIP/Postal code, City<br>Usage
        Data<br>Usage Data</p>
      <p>Usage Data is collected automatically when using the Service.</p>
      <p>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser
        type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent
        on those pages, unique device identifiers and other diagnostic data.</p>
      <p>When You access the Service by or through a mobile device, We may collect certain information automatically,
        including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address
        of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device
        identifiers and other diagnostic data.</p>
      <p>We may also collect information that Your browser sends whenever You visit our Service or when You access the
        Service by or through a mobile device.</p>
      <p>Information Collected while Using the Application</p>
      <p>While using Our Application, in order to provide features of Our Application, We may collect, with Your prior
        permission:</p>
      <p>Information regarding your location<br>Pictures and other information from your Device's camera and photo
        library<br>We use this information to provide features of Our Service, to improve and customize Our Service. The
        information may be uploaded to the Company's servers and/or a Service Provider's server or it may be simply
        stored on Your device.</p>
      <p>You can enable or disable access to this information at any time, through Your Device settings.</p>
      <p>Use of Your Personal Data</p>
      <p>The Company may use Personal Data for the following purposes:</p>
      <p>To provide and maintain our Service, including to monitor the usage of our Service.<br>To manage Your Account:
        to manage Your registration as a user of the Service. The Personal Data You provide can give You access to
        different functionalities of the Service that are available to You as a registered user.<br>For the performance
        of a contract: the development, compliance and undertaking of the purchase contract for the products, items or
        services You have purchased or of any other contract with Us through the Service.To contact You: To contact You
        by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile
        application's push notifications regarding updates or informative communications related to the functionalities,
        products or contracted services, including the security updates, when necessary or reasonable for their
        implementation.<br>To provide You with news, special offers and general information about other goods, services
        and events which we offer that are similar to those that you have already purchased or enquired about unless You
        have opted not to receive such information.<br>To manage Your requests: To attend and manage Your requests to
        Us.<br>For business transfers: We may use Your information to evaluate or conduct a merger, divestiture,
        restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a
        going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us
        about our Service users is among the assets transferred.<br>For other purposes: We may use Your information for
        other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our
        promotional campaigns and to evaluate and improve our Service, products, services, marketing and your
        experience.<br>We may share Your personal information in the following situations:</p>
      <p>With Service Providers: We may share Your personal information with Service Providers to monitor and analyze
        the use of our Service, to contact You.<br>For business transfers: We may share or transfer Your personal
        information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or
        acquisition of all or a portion of Our business to another company.<br>With Affiliates: We may share Your
        information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy.
        Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that
        We control or that are under common control with Us.<br>With business partners: We may share Your information
        with Our business partners to offer You certain products, services or promotions.<br>With other users: when You
        share personal information or otherwise interact in the public areas with other users, such information may be
        viewed by all users and may be publicly distributed outside.<br>With Your consent: We may disclose Your personal
        information for any other purpose with Your consent.<br>Retention of Your Personal Data</p>
      <p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this
        Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal
        obligations (for example, if we are required to retain your data to comply with applicable laws), resolve
        disputes, and enforce our legal agreements and policies.</p>
      <p>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a
        shorter period of time, except when this data is used to strengthen the security or to improve the functionality
        of Our Service, or We are legally obligated to retain this data for longer time periods.</p>
      <p>Transfer of Your Personal Data</p>
      <p>Your information, including Personal Data, is processed at the Company's operating offices and in any other
        places where the parties involved in the processing are located. It means that this information may be
        transferred to &mdash; and maintained on &mdash; computers located outside of Your state, province, country or
        other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.</p>
      <p>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement
        to that transfer.</p>
      <p>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in
        accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or
        a country unless there are adequate controls in place including the security of Your data and other personal
        information.</p>
      <p>Delete Your Personal Data</p>
      <p>You have the right to delete or request that We assist in deleting the Personal Data that We have collected
        about You.</p>
      <p>Our Service may give You the ability to delete certain information about You from within the Service.</p>
      <p>You may update, amend, or delete Your information at any time by signing in to Your Account, if you have one,
        and visiting the account settings section that allows you to manage Your personal information. You may also
        contact Us to request access to, correct, or delete any personal information that You have provided to Us.</p>
      <p>Please note, however, that We may need to retain certain information when we have a legal obligation or lawful
        basis to do so.</p>
      <p>Disclosure of Your Personal Data</p>
      <p>Business Transactions</p>
      <p>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We
        will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.
      </p>
      <p>Law enforcement</p>
      <p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by
        law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
      <p>Other legal requirements</p>
      <p>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>
      <p>Comply with a legal obligation<br>Protect and defend the rights or property of the Company<br>Prevent or
        investigate possible wrongdoing in connection with the Service<br>Protect the personal safety of Users of the
        Service or the public<br>Protect against legal liability<br>Security of Your Personal Data</p>
      <p>The security of Your Personal Data is important to Us, but remember that no method of transmission over the
        Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means
        to protect Your Personal Data, We cannot guarantee its absolute security.</p>
      <p>Detailed Information on the Processing of Your Personal Data</p>
      <p>The Service Providers We use may have access to Your Personal Data. These third-party vendors collect, store,
        use, process and transfer information about Your activity on Our Service in accordance with their Privacy
        Policies.</p>
      <p>Usage, Performance and Miscellaneous</p>
      <p>We may use third-party Service Providers to provide better improvement of our Service.</p>
      <p>Google Places</p>
      <p>Google Places is a service that returns information about places using HTTP requests. It is operated by Google
      </p>
      <p>Google Places service may collect information from You and from Your Device for security purposes.</p>
      <p>Children's Privacy</p>
      <p>Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable
        information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child
        has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data
        from anyone under the age of 13 without verification of parental consent, We take steps to remove that
        information from Our servers.</p>
      <p>If We need to rely on consent as a legal basis for processing Your information and Your country requires
        consent from a parent, We may require Your parent's consent before We collect and use that information.</p>
      <p>Links to Other Websites</p>
      <p>Our Service may contain links to other websites that are not operated by Us. If You click on a third party
        link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of
        every site You visit.</p>
      <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any
        third party sites or services.</p>
      <p>Changes to this Privacy Policy</p>
      <p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new
        Privacy Policy on this page.</p>
      <p>We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective
        and update the "Last updated" date at the top of this Privacy Policy.</p>
      <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are
        effective when they are posted on this page.</p>
      <p>Contact Us</p>
      <p>If you have any questions about this Privacy Policy, You can contact us:<br>By email:
        alisher-forex@mail.ru<br>By visiting this page on our website: https://crm.salomhayot.uz/#/home</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p><br>Public offer of the online store "crm.salomhayot.uz"</p>
      <p>This public offer (hereinafter referred to as the Agreement) is an official offer of Walmar Wordwide LLC
        (hereinafter referred to as LLC) to any individual or legal entity that has the legal capacity and the necessary
        authority to conclude a contract for the sale of Goods with Walmart Wordwide LLC on the terms defined in this
        offer, and contains all the essential terms of the contract.</p>
      <p>I. TERMS AND DEFINITIONS</p>
      <p>1.1. The terms and concepts used in the Agreement are in some cases specially explained by the relevant
        definitions. The definitions of terms and concepts given in the Agreement apply to all its provisions and the
        legal relations of the Parties arising from it. If the meaning of any term or concept is not specified by the
        Agreement and cannot be determined on the basis of legislation and regulations, then such term or concept is
        used in its usual lexical meaning.</p>
      <p>1.2. Basic terms and definitions used in the Agreement:</p>
      <p>Public offer is a real public offer approved by the Director of the Seller. The effect of this Public Offer is
        governed by Articles 369, 426 of the Civil Code of the Republic of Uzbekistan.</p>
      <p>Seller &ndash; LLC &ldquo;Walmart Wordwide&rdquo;</p>
      <p>Buyer - any individual (residents and non-residents of the Republic of Uzbekistan) who purchases goods (works,
        services) using information systems for final consumption, who has accepted a public offer on the terms of this
        offer.</p>
      <p>The product is an object of agreement between the parties, a list of assortment names presented in the official
        online store of Walmart Wordwide LLC at the Internet address: https://crm.salomhayot.uz</p>
      <p>Online store - the official online store of the Seller LLC "Walmart Wordwide", located at the Internet address
        https://crm.salomhator.uz</p>
      <p>Seller's email address - Seller's email address</p>
      <p>Buyer's email address - the Buyer's email address specified in the Application or the email address from which
        the Application was sent.</p>
      <p>An e-commerce participant is an individual who is a Seller or Buyer of goods (works, services) in e-commerce.
      </p>
      <p>Bonuses - virtual funds accrued for the purchase of certain goods and having an expiration date.</p>
      <p>Personal account - a personal part of the Internet store "https://crm.salomhayot.uz", allocated to the User.
      </p>
      <p>Price-list - a document valid for the Seller, containing information about the prices of the Goods.</p>
      <p>Acceptance - agreement with the proposal (offer) to conclude a contract on the terms specified in the proposal
        entailing legal consequences.</p>
      <p>Selling Price - the price of the Goods (including all applicable taxes and transport charges).</p>
      <p>The final price is the total amount payable by the buyer to Salomhayot in 12 or 24 month installments,
        including principal and annual commission.</p>
      <p>Commission Annual interest paid to the Salomhayot on a monthly basis according to the payment schedule
        (principal and interest shown together), calculated on the purchase price.</p>
      <p>II. GENERAL PROVISIONS</p>
      <p>2.1. The order by the Buyer of the Goods posted on the website of the online store means that the Buyer agrees
        with all the terms of this Offer, which is a direct acceptance of the terms of the Offeror's agreement.</p>
      <p>2.2. The administration of the website of the online store has the right to make changes to the Offer without
        notifying the Buyer. In case of making changes to the current Offer, the changes come into force after 10
        calendar days from the date of official publication on the website.</p>
      <p>2.3. The validity period of the Offer is not limited, unless otherwise specified on the website of the online
        store.</p>
      <p>III. REGISTRATION ON THE SITE</p>
      <p>3.1. Everyone can buy goods on the Online Store, but only registered Buyers can buy in installments in the
        Online Store, while the Buyer has the right to register on the site only 1 (one) time, i.e. can have only one
        Personal Account.</p>
      <p>3.2. The Seller is not responsible for the accuracy and correctness of the information provided by the Buyer
        during registration.</p>
      <p>3.3. The Buyer who has registered in the Online Store receives individual identification by entering an SMS
        with an access code sent by the Seller to the contact number specified in the profile. Individual identification
        of the Buyer allows to avoid unauthorized actions of third parties on behalf of the Buyer and provides access to
        additional services. The transfer of the access code by the Buyer to third parties is prohibited.</p>
      <p>3.4. You can use the discount of a regular Buyer only through the Personal Account. To do this, you need to
        make orders after entering the site under your credentials specified in the profile of your personal account.
        The Regular Customer discount is tied to a specific Personal Account, because is calculated on the basis of
        purchases (deliveries) made through it. In case of re-registration on the site, the history of purchases
        (deliveries) and the discount of a regular Buyer are not transferred from the old Personal Account to the new
        one. Order statistics in the new Personal Account are kept from scratch. You can use the discount only through
        the Personal Account in which it is generated.</p>
      <p>IV. THE PRICE OF THE PRODUCT</p>
      <p>4.1. The prices for the Goods are determined by the Seller unilaterally indisputably and are indicated on the
        pages of the online store located at the Internet address: https://crm.salomhayot.uz The price of the goods
        includes all taxes (including VAT), fees and payments , the cost of the factory container and packaging.</p>
      <p>4.2. The price of the Goods is indicated in the national currency of the Republic of Uzbekistan &ndash; sum.
      </p>
      <p>4.3. The Seller has the right to unilaterally change the price for any position of the Goods. At the same time,
        new (changed) prices come into force from the date of official publication on the website.</p>
      <p>4.4. Change by the Seller of the price of the Goods paid by the Buyer is not allowed.</p>
      <p>4.5. In case of payment for the Goods in cash, the Seller is obliged to issue to the Buyer a cash receipt
        confirming the payment, in accordance with the current legislation of the Republic of Uzbekistan.</p>
      <p>V. RIGHTS AND OBLIGATIONS OF THE PARTIES</p>
      <p>5.1. The seller undertakes:</p>
      <p>5.1.1. From the moment of conclusion of this Agreement, fully ensure all obligations to the Buyer in accordance
        with the terms of this agreement and current legislation.</p>
      <p>5.1.2. Process the Buyer's personal data in the manner prescribed by applicable law regarding the organization
        of processing and ensuring the security of personal data.</p>
      <p>5.1.3. Provide the Buyer with the opportunity to receive free telephone consultations by phone numbers
        indicated on the website of the online store (https://crm.salomhayot.uz). The scope of consultations is limited
        to specific issues related to the fulfillment of the Order and the characteristics of the Goods.</p>
      <p>5.1.4. Prevent attempts of unauthorized access to information and / or transfer it to persons not directly
        related to the execution of Orders, detect and suppress such facts in a timely manner.</p>
      <p>5.2. The seller has the right:</p>
      <p>5.2.1. Change this Agreement, the Prices for the Goods and the Tariffs for related services, the methods and
        terms of payment and delivery of the goods unilaterally by placing them on the pages of the online store located
        at the Internet address: https://crm.salomhayot.uz. All changes come into force immediately after publication,
        and are considered brought to the attention of the Buyer from the moment of such publication.</p>
      <p>5.2.2. Record telephone conversations with the Buyer.</p>
      <p>5.2.3. Without agreement with the Buyer, transfer his rights and obligations to fulfill the Agreement to third
        parties.</p>
      <p>5.2.4. Use "cookies" technology. Cookies do not contain confidential information and are not transferred to
        third parties.</p>
      <p>5.2.5. Receive information about the ip - address of the visitor to the Site https://crm.salomhayot.uz. This
        information is not used to identify the visitor and is not transferable to third parties.</p>
      <p>5.2.6. The Seller has the right to send advertising and informational messages to the Buyer via e-mail and sms
        with information about discounts, promotions, new arrivals, etc. The frequency of mailings is determined by the
        Seller independently, unilaterally.</p>
      <p>5.2.7. The Seller has the right to refuse the Buyer to accept and execute the order if the Buyer made an
        earlier order and did not pay for it, refused the order at the time of delivery of the Goods, did not accept the
        delivered Goods, or if the Seller has doubts about the accuracy of the specified data of the Buyer, without
        explaining the reasons.</p>
      <p>5.2.8. By posting a review on the site, the Buyer consents to the use by the Seller of the review text for
        advertising purposes, as well as transfer to third parties for the use of the review text on third-party
        resources. At the same time, in order to ensure the safety of the Buyer's personal data, a review may be posted
        with a reduction or partial masking of personal data.</p>
      <p>5.2.9. When delivering a prepaid order, the Seller or the Carrier has the right to require the Buyer to present
        an identity document or a Power of Attorney to receive the Goods. In case of refusal to present the specified
        document, the online store has the right to refuse to issue the goods to the Buyer.</p>
      <p>5.3. The buyer undertakes:</p>
      <p>5.3.1. Before the conclusion of the Agreement, familiarize yourself with the content and terms of the
        Agreement, the prices for the Goods offered by the Seller in the online store, as well as other conditions on
        the pages of the online store located at the Internet address: https://crm.salomhayot.uz</p>
      <p>5.3.2. In fulfillment by the Seller of its obligations to the Buyer, the latter gives full consent to the
        transfer and processing (in any form) of his personal data and must provide all the necessary data that uniquely
        identify him as the Buyer and sufficient to deliver the Goods he paid for to the Buyer.</p>
      <p>5.3.3. Pay for the ordered Goods and its delivery on the terms of this agreement.</p>
      <p>5.4. The buyer has the right:</p>
      <p>5.4.1. The buyer has the right to refuse to receive e-mail and sms mailings, for this he needs to click on the
        link in the letter &ldquo;You can unsubscribe from the mailing list from your Personal Account&rdquo; or go to
        the &ldquo;Subscriptions&rdquo; page in the Personal Account.</p>
      <p>5.4.2. Independently cancel the order through a special service on the website or in the Seller's application
        within 10 minutes from the moment the order was placed.</p>
      <p>5.4.3. Pay for and accept the ordered Goods from the Buyer or a third party (a legal entity providing services
        for the delivery of the Goods with which the Buyer has entered into an agreement for the provision of transport
        and forwarding services) delivered to a previously agreed address (territory).</p>
      <p>VI. ORDERING</p>
      <p>6.1. The order of the Goods is carried out by the Buyer through the service of the website Intern online store
        https://crm.salomhayot.uz. The Buyer is obligated to clarify the availability of the goods and more detailed
        information about the product from the website operator by phone +998 78 333 38 33 (in case the Buyer neglects
        this procedure, the Seller does not bear any responsibility for the consequences arising from the fault of the
        Buyer who did not carry out this procedure).</p>
      <p>6.2. When registering on the website of the online store, the Buyer undertakes to voluntarily provide the
        following registration personal information (with the right to process, store and use it in any way with the
        right to transfer to third parties, if necessary, on the part of the Seller) information:</p>
      <p>6.2.1. Surname, name, patronymic or the person indicated by him (recipient);</p>
      <p>6.2.2. The address to which the Goods should be delivered (if the delivery is to the address of the Buyer);</p>
      <p>6.2.3. E-mail address;</p>
      <p>6.2.4. Contact phone numbers.</p>
      <p>6.3. If the Seller needs additional information, he has the right to request it from the Buyer. If the Buyer
        does not provide the necessary information, the Seller is not responsible for the Goods chosen by the Buyer.</p>
      <p>6.4. Acceptance by the Buyer of the terms of this Offer is carried out by entering the relevant data into the
        registration form on the website of the Online Store or when placing an Order through the Operator. After
        placing an Order through the Operator, the Buyer's data is registered in the Seller's database. Having approved
        the Order for the selected Goods, the Buyer provides the Operator with the necessary information in accordance
        with the procedure specified in clause 6.2. of this Offer.</p>
      <p>6.5. The Seller is not responsible for the content and accuracy of the information provided by the Buyer when
        placing an Order.</p>
      <p>6.6. The Buyer is responsible for the accuracy of the information provided when placing the Order.</p>
      <p>6.7. The remote sale and purchase agreement between the Seller and the Buyer is considered concluded from the
        moment the Order is placed.</p>
      <p>VII. DELIVERY AND TRANSFER OF GOODS</p>
      <p>7.1. Delivery of the Goods is carried out by one of the following methods agreed by the Parties:</p>
      <p>- by the Seller (the date of sale will be the date of transfer of the Goods to the Buyer or the Consignee
        specified by the Buyer);</p>
      <p>- the carrier (courier) with which the Seller has concluded the relevant contract (the date of sale will be the
        date of transfer of the Goods to the Buyer or the Consignee specified by the Buyer);</p>
      <p>- by the forces and at the expense of the Buyer, on the terms of self-pickup from the Seller's warehouse (the
        date of sale will be the date of transfer of the Goods to the authorized representative of the Buyer at the
        Seller's warehouse).</p>
      <p>When organizing the delivery of the Goods by the Seller, the delivery is carried out free of charge (not on a
        commercial basis).</p>
      <p>7.2. The term of delivery of the Goods to the Buyer consists of the order processing time and the delivery
        time.</p>
      <p>7.3. The buyer must provide an identity document or a power of attorney for his representative to receive the
        goods.</p>
      <p>The release of goods by proxy is not carried out in the following cases:</p>
      <p>- Presentation of a power of attorney issued with violations of the established procedure for its completion;
      </p>
      <p>- Presentation of a power of attorney with amendments and blots;</p>
      <p>- Failure to present a passport (copy of the passport) specified in the power of attorney;</p>
      <p>- Expiration of the power of attorney.</p>
      <p>7.4. Acceptance of the Goods in terms of quantity, completeness and quality is carried out according to the
        accompanying documents at the place of transfer of the Goods to the Buyer or the Consignee.</p>
      <p>7.5. The Seller transfers, and the Buyer accepts and pays for the goods on the terms specified in this
        agreement. Ownership of the Ordered Goods passes to the Buyer from the moment the Goods are actually transferred
        to the Buyer and the latter pays the full cost of the Goods. The risk of accidental loss or damage to the Goods
        passes to the Buyer from the moment the Goods are actually transferred to the Buyer.</p>
      <p>VIII. GOODS QUALITY AND GOODS WARRANTY</p>
      <p>8.1. The quality of the product must comply with international standards for the corresponding product in the
        country of origin of the product.</p>
      <p>8.2. The Seller guarantees that the goods sold are new and unused.</p>
      <p>8.3. The warranty period for the Goods is determined in accordance with the issued warranty card or the
        Regulation on warranty service accepted by the Seller.</p>
      <p>8.4. The warranty does not cover defects in the Goods caused by:</p>
      <p>- incorrect (in violation of the instructions attached to the product in the set of technical documentation) or
        inappropriate use of the product by the Buyer;</p>
      <p>- use of the Goods in unforeseen environmental conditions, irregular maintenance;</p>
      <p>- a voltage drop in the mains below or above that specified in the technical documentation of the Goods;</p>
      <p>IX. RETURN AND EXCHANGE OF GOODS</p>
      <p>9.1. Goods of proper quality:</p>
      <p>9.1.1. The buyer has the right, within ten days from the date of purchase, to exchange a non-food product of
        good quality for a similar one from the seller where it was purchased, and in the absence of such a product on
        sale, receive monetary compensation.</p>
      <p>9.1.2. Buyer's request for an exchange non-refundable goods are subject to satisfaction in the following cases:
      </p>
      <p>- if the goods were not in use;</p>
      <p>- its presentation, consumer properties, seals, labels are preserved;</p>
      <p>- a document confirming the fact and conditions of purchase of the specified goods (sales receipt or cash
        receipt);</p>
      <p>9.1.3. Goods of good quality are not subject to return (are not accepted back), in accordance with Appendix 1
        to the Rules of Retail Trade of the Republic of Uzbekistan, approved by the Resolution of the Cabinet of
        Ministers of the Republic of Uzbekistan No. 75 dated February 13, 2003:</p>
      <p>- Goods for the prevention and treatment of diseases at home (sanitary and hygiene items made of metal, rubber,
        textiles and other materials, medical products and medical equipment, oral hygiene products, spectacle lenses,
        child care items, medicines).</p>
      <p>- Personal hygiene items (toothbrushes, combs, hair clips, hair curlers, wigs, hairpieces and other similar
        products).</p>
      <p>- Perfume and cosmetic products.</p>
      <p>- Textile goods (cotton, linen, silk, woolen and synthetic fabrics, goods from non-woven materials such as
        fabrics - ribbons, braid, lace and others); cable products (wires, cords, cables); building and finishing
        materials (linoleum, film, carpets, etc.) and other goods sold per meter.</p>
      <p>- Sewing and knitwear (clothing and knitted underwear, hosiery and gloves).</p>
      <p>- Products and materials in contact with food, made of polymeric materials, including those for single use
        (dishes and tableware and kitchen utensils, containers and packaging materials for storing and transporting food
        products).</p>
      <p>- Household chemicals, pesticides and agrochemicals.</p>
      <p>- Furniture (furniture sets and sets).</p>
      <p>- Products made of precious metals, with precious stones, made of precious metals with inserts of semi-precious
        and synthetic stones, faceted precious stones.</p>
      <p>- Cars and motorcycle products, trailers and numbered units for them; mobile means of small-scale mechanization
        of agricultural work; pleasure boats and other watercraft for domestic use.</p>
      <p>- Technically sophisticated household goods for which warranty periods are established (household metal-cutting
        and woodworking machines; household electrical machines and household appliances; household radio-electronic
        equipment; household computing and duplicating equipment; photographic and film equipment; telephone sets and
        facsimile equipment; electric musical instruments; toys electronic).</p>
      <p>- Animals and plants.</p>
      <p>9.1.4. Return of goods is carried out through the "Delivery Service" of the Seller, for this it is necessary:
      </p>
      <p>- call the online store at the numbers indicated in the "Contacts" section;</p>
      <p>- print and fill out the "Application for return";</p>
      <p>- attach a sales or cash receipt of the order to the return application.</p>
      <p>9.2. Goods of inadequate quality:</p>
      <p>9.2.1. A consumer to whom goods with defects are sold, if defects are discovered during the warranty period or
        the expiration date of the goods or within six months for goods for which no deadlines are established, has to
        demand:</p>
      <p>- gratuitous elimination of defects in the goods or reimbursement of expenses for the correction of defects by
        the consumer or a third party;</p>
      <p>- with the written consent of the Seller, a commensurate reduction in the purchase price;</p>
      <p>The requirements of the consumer are considered upon presentation of a cash or sales receipt, and for goods for
        which warranty periods are established, a properly executed technical passport or other document replacing it.
      </p>
      <p>9.2.2. The procedure for returning goods of inadequate quality is carried out as follows:</p>
      <p>- By submitting an application for the return of the Goods, which must indicate which defect contains the goods
        returned by the Buyer;</p>
      <p>- After accepting the application, the Goods are sent for inspection to the technical service for the provision
        of services, which is authorized to carry out a technical inspection of a certain type of Goods to determine the
        causes of defects in the Goods;</p>
      <p>- Based on the results of the inspection, an authorized technical service for the provision of services draws
        up a conclusion on the causes of the defects of the Goods.</p>
      <p>- If during the warranty period the defects of the Goods were the cause of a factory defect (defect), the Goods
        must be replaced, and if it is possible to technically eliminate the shortcomings, the Goods must be eliminated
        free of charge.</p>
      <p>9.2.3. The procedure for replacing goods with defects:</p>
      <p>- Within 7 days (with obvious signs of factory defects)</p>
      <p>- Within 20 days (if additional verification is required)</p>
      <p>- Within 1 month (in the absence of a product of a similar brand)</p>
      <p>- Within 2 months (for delivery to remote areas).</p>
      <p>9.2.4. The procedure for eliminating product defects:</p>
      <p>- Within 20 days (after a full check by the technical service for the provision of services).</p>
      <p>X. PAYMENT METHODS</p>
      <p>10.1. The Buyer can make payment in the following ways:</p>
      <p>- payment in cash;</p>
      <p>- bank payment which plastic card;</p>
      <p>- payment through the payment systems indicated in the Internet address: https://crm.salomhayot.uz</p>
      <p>- non-cash payment (for legal entities).</p>
      <p>10.2. More detailed information on the methods of payment for goods is contained in the section of the website
        "Methods of payment".</p>
      <p>XI. FORCE MAJEURE</p>
      <p>11.1. The Parties are released from liability for partial or complete failure to fulfill obligations if such
        failure is caused by force majeure circumstances that arose after the conclusion of the Agreement as a result of
        extraordinary events that the Parties could not foresee and prevent by reasonable measures (force majeure). In
        this case, neither party has the right to demand compensation for possible losses, except for the obligation to
        return previously received funds.</p>
      <p>11.2. "Force Majeure Events" means extraordinary events or circumstances that such Party could not foresee or
        prevent by the means available to it. Such extraordinary events or circumstances include, in particular:
        strikes, floods, fires, earthquakes and other natural disasters, wars, hostilities, actions of state bodies of
        the Republic of Uzbekistan or other states, as well as any other circumstances beyond the reasonable control of
        any from the Parties.</p>
      <p>11.3. Changes in the current legislation or regulations that directly or indirectly affect any of the Parties
        are not considered as Force Majeure Events, however, in the event of such changes that prevent any of the
        Parties from fulfilling any of its obligations under this Agreement, The Parties are obliged to immediately
        decide on the procedure for working to eliminate this problem in order to ensure the continued execution of this
        Agreement by the Parties.</p>
      <p>XII. RESPONSIBILITIES OF THE PARTIES</p>
      <p>12.1. For non-fulfillment or improper fulfillment of the terms of this Agreement, the Parties shall be liable
        in accordance with the legislation of the Republic of Uzbekistan.</p>
      <p>12.2. All textual information, graphic images and services posted in the online store have a legal owner, the
        illegal use of this information and images is prosecuted in accordance with applicable law.</p>
      <p>XIII. OTHER CONDITIONS</p>
      <p>13.1. All disputes related to non-fulfillment, or improper fulfillment of their obligations under this
        agreement, the parties will try to resolve during negotiations.</p>
      <p>13.2. In case of failure to reach an agreement during the negotiations, disputes will be resolved in court in
        accordance with the current legislation of the Republic of Uzbekistan.</p>
      <p>13.3. The sections of the online store explaining the rules and conditions referred to in the Offer are an
        integral part of this Offer.</p>
      <p>13.4. By accepting this offer, the Buyer agrees and allows Walmart Wordwide LLC to process his personal data,
        including last name, first name, patronymic, date of birth, gender, place of work and position, postal address,
        home, work, mobile phones, e-mail address , including the collection, systematization, accumulation, storage,
        clarification (update, change), use, distribution, transfer on the territory of the Republic of Uzbekistan,
        depersonalization, blocking, destruction of personal data, as well as transferring them to the Seller's
        counterparties for the purpose of further processing (including collection, systematization , accumulation,
        storage, clarification (updating, changing), use, distribution on the territory of the Republic of Uzbekistan,
        depersonalization, blocking, destruction of personal data) to conduct research aimed at improving the quality of
        services, to conduct marketing programs, statistical research, as well as to promote services on the market
        through direct contacts with the Buyer using various means of communication, including, but not limited to:
        mailing list, e-mail, telephone, facsimile, Internet.</p>
      <p>13.5. The Buyer agrees and allows the Seller and the Seller's counterparties to process the Buyer's personal
        data using automated database management systems, as well as other software tools specially developed on behalf
        of the Seller. Work with such systems is carried out according to the algorithm prescribed by the operator
        (collection, systematization, accumulation, storage, clarification, use, blocking, destruction). The processing
        methods used (including, but not limited to): automatic verification of postal codes with the code base,
        automatic verification of the spelling of street names / settlements, clarification of data with the Buyer by
        telephone, postal communication with the Buyer or via contact via the Internet, database segmentation according
        to the given criteria. The Buyer agrees that, if it is necessary to achieve the goals specified in this offer,
        his personal data received by the Seller may be transferred to third parties to whom the Seller may entrust the
        processing of the Buyer's personal data on the basis of an agreement concluded contracted with such persons,
        subject to the requirements of the legislation of the Republic of Uzbekistan on ensuring by such third parties
        the confidentiality of personal data and the security of personal data during their processing. When
        transferring the specified data of the Buyer, the Seller warns the persons receiving the personal data of the
        Buyer that these data are confidential and can only be used for the purposes for which they are reported, and
        requires these persons to comply with this rule.</p>
      <p>13.6. The Buyer has the right to request from the Seller full information about his personal data, their
        processing and use, as well as to demand the exclusion or correction / addition of incorrect or incomplete
        personal data by sending a corresponding written request addressed to the Seller at the postal address. The
        consent given by the Buyer to the processing of his personal data is unlimited and can be withdrawn by sending a
        written application to the Seller's postal address by the Buyer.</p>
      <p>XIV. SELLER'S DETAILS</p>
      <p>Legal name: Walmart Wordwide LLC</p>
      <p>Office location: Bukhara city, st. T.Farogiy, house № 74/1</p>
      <p>Phone number: +998 78 333 38 33</p>
      <p>Helpline: +998 55 303 30 00</p>
      <p>A/c - 20208 000 8050 48 115 001</p>
      <p>JSICB "Ipak Yuli" branch Bukhara Bank code: 00088</p>
      <p>TIN: 306 298 241</p>
      <p>Director: Nurov I.I.</p>
      <!-- Comments are visible in the HTML source only -->
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
    }
  },
  computed: {

  },
  created() {
  },
}
</script>

<style lang="scss">
.contact-info {
  font-size: 24px;
  line-height: 32px;
  font: inherit;
  color: #000;
  font-weight: 800;
}
</style>
